import React, { useState, useEffect } from "react";
import { css } from "@emotion/css";
import { Item } from "../components";
import { getAuthors } from "../common";

export const Authors = () => {
  const [authors, setAuthors] = useState([]);

  useEffect(() => {
    getAuthors().then((authors) =>
      setAuthors(
        authors.sort((a, b) =>
          a.name.localeCompare(b.name, "sv", { sensitivity: "base" })
        )
      )
    );
  }, []);

  return (
    <div
      className={css`
        margin-top: 1rem;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
      `}
    >
      <div
        className={css`
          font-family: Playfair Display;
          font-size: 2rem;
          font-weight: 700;
          color: var(--dark-grey);
        `}
      >
        Författare
      </div>

      {authors.map((author, index) => (
        <Item
          type="author"
          imgSrc={`${author.key}.jpg`}
          name={author.name}
          about={author.info}
          key={index}
        />
      ))}
    </div>
  );
};
