import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  getDocs,
  query,
  where,
} from "firebase/firestore/lite";

// TODO: save as env variables
const firebaseConfig = {
  apiKey: "AIzaSyBkmWkaPgIx3w_b2_F_2_ZO7-Urbe3lTxo",
  authDomain: "omniprint-46877.firebaseapp.com",
  projectId: "omniprint-46877",
  storageBucket: "omniprint-46877.appspot.com",
  messagingSenderId: "287163863199",
  appId: "1:287163863199:web:3ac94cd4ab784292f912aa",
  measurementId: "G-YJ5J4X5QE0",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const AUTHORS_COLLECTION_NAME = "authors";
const BOOKS_COLLECTION_NAME = "books";

function getItemData(item, collectionName) {
  if (!item.exists()) {
    return undefined;
  }
  return { key: item.id, ...item.data() };
}

async function readData(collectionName) {
  return await getDocs(collection(db, collectionName)).then(
    async (snapshot) =>
      await snapshot.docs.map((item) => getItemData(item, collectionName))
  );
}

async function readDataWithQuery(
  collectionName,
  queryField,
  queryOperator,
  queryValue
) {
  return await getDocs(
    query(
      collection(db, collectionName),
      where(queryField, queryOperator, queryValue)
    )
  ).then(
    async (snapshot) =>
      await snapshot.docs.map((item) => getItemData(item, collectionName))
  );
}

export async function getAuthors() {
  return await readData(AUTHORS_COLLECTION_NAME);
}

export async function getReleasedBooks() {
  return readDataWithQuery(
    BOOKS_COLLECTION_NAME,
    "releaseDate",
    "<=",
    new Date()
  );
}

export async function getUpcomingBooks() {
  return readDataWithQuery(
    BOOKS_COLLECTION_NAME,
    "releaseDate",
    ">",
    new Date()
  );
}
