import React from "react";
import { css } from "@emotion/css";

export const Footer = () => {
  return (
    <div
      className={css`
        padding: 5rem 0;
      `}
    >
      <a
        className={css`
          font-weight: 300;
          color: var(--medium-grey);
          text-decoration: none;
        `}
        href="mailto:info@omniprintforlag.se"
      >
        info@omniprintforlag.se
      </a>
    </div>
  );
};
