export function getDate(dateString) {
  if (!dateString) {
    return undefined;
  }

  const date =
    typeof dateString === "string" ? new Date(dateString) : dateString.toDate();

  const month =
    date.getMonth() < 10 ? `0${date.getMonth() + 1}` : `${date.getMonth() + 1}`;

  const day = date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`;

  return isValidDate(date)
    ? `${date.getFullYear()}-${month}-${day}`
    : undefined;
}

function isValidDate(date) {
  return date instanceof Date && !isNaN(date);
}
