import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { css, cx } from "@emotion/css";

export const Navbar = ({ navbarItems }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname === "/" ? "/start" : location.pathname;

  return (
    <div
      className={css`
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
      `}
    >
      {navbarItems.map((item, index) => (
        <div
          className={cx(
            css`
              font-family: Playfair Display;
              font-size: 1.25rem;
              transform: scale(1.1, 1);
              font-weight: 500;
              margin: 0 1rem;
              background: linear-gradient(
                  to left,
                  transparent 51%,
                  var(--orange) 50%
                )
                right;
              background-size: 200%;
              transition: 0.5s ease-out;

              &:hover {
                cursor: pointer;
                color: white;
                background-position: left;
              }
            `,
            pathname.includes(item.id)
              ? css`
                  color: white;
                  background-position: left;
                `
              : css`
                  background-position: right;
                `
          )}
          onClick={() => navigate(`/${item.id === "start" ? "" : item.id}`)}
          key={index}
        >
          {item.name.toLowerCase()}
        </div>
      ))}
    </div>
  );
};
