import React from "react";
import { CoverImage, InfoText } from "../components";

export const How = () => {
  return (
    <>
      <CoverImage imgSrc="coverImg/how.jpg" title="HUR DET GÅR TILL" />
      <InfoText title="Hur det går till">
        Om förlaget bedömer att ditt manus har tillräckligt hög kvalitet för en
        utgivning hjälper vi dig genom hela processen från manus till färdig
        bok. Upplägget är enkelt: vi producerar din bok och du står för
        kostnaden.
        <br />
        <br />
        Det redaktionella arbetet är ofta det viktigaste arbetet i processen
        från manus till färdig bok. Vi på förlaget hjälper dig med att lyfta
        texten och ser till att den text du lämnat ifrån dig når en så
        konstnärligt och språkligt hög nivå som möjligt. Vi korrekturläser den
        satta texten och ser till att din text blir grammatiskt och ortografiskt
        korrekt.
        <br />
        <br />
        Tillsammans bestämmer vi utformningen av boken. &Auml;r det en bilderbok
        du vill ge ut kanske du behöver en illustratör. Förlaget har ett stort
        kontaktnät av illustratörer och en mångårig erfarenhet när det gäller
        att välja lämplig illustratör till en viss text. Tillsammans väljer vi
        papper och format och bestämmer omslagets utformning.
        <br />
        <br />
        Omniprint Förlag trycker på utvalda tryckerier i Baltikum. Vi ser alltid
        till att trycka på det tryckeri som passar bäst för just din bok, både
        beträffande kvalitet och pris.
        <br />
        <br />
        Den upphovsmässiga rättigheten till texten i boken innehas av dig,
        vilket även gäller ägandet av de fysiska exemplaren av boken. Har du
        lämnat eget bildmaterial till boken är även rättigheterna till detta
        ditt.
        <br />
        <br />
        När boken är klar vill du förmodligen att den ska sälja så bra som
        möjligt. Till glädje för många debutanter och mindre kända författare
        kan en författare i dag få stor uppmärksamhet för en bok, även om boken
        inte ges ut på ett traditionellt förlag. Detta då en bok i dag inte bara
        säljer genom den uppmärksamhet den får i dagstidningar och traditionell
        media, utan även genom den uppmärksamhet den får i sociala medier.
      </InfoText>
    </>
  );
};
