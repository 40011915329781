import React from "react";
import { css } from "@emotion/css";

export const InfoText = ({ children, title }) => {
  return (
    <div
      className={css`
        font-size: 1rem;
        text-align: justify;
        color: var(--dark-grey);
        line-height: 175%;
      `}
    >
      <div
        className={css`
          margin: 3rem 0 1rem 0;
          font-family: Playfair Display;
          font-size: 2rem;
          font-weight: 700;
          color: var(--orange);
        `}
      >
        {title}
      </div>
      {children}
    </div>
  );
};
