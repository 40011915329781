import React, { useState, useEffect } from "react";
import { css } from "@emotion/css";
import { Item } from "../components";
import { getReleasedBooks, getUpcomingBooks } from "../common";

export const Books = () => {
  const [releasedBooks, setReleasedBooks] = useState([]);
  const [upcomingBooks, setUpcomingBooks] = useState([]);
  const cssHeader = css`
    font-family: Playfair Display;
    font-size: 2rem;
    font-weight: 700;
    color: var(--dark-grey);
  `;

  useEffect(() => {
    getReleasedBooks().then((books) =>
      setReleasedBooks(
        books.sort((a, b) =>
          a.name.localeCompare(b.name, "sv", { sensitivity: "base" })
        )
      )
    );
  }, []);

  useEffect(() => {
    getUpcomingBooks().then((books) => {
      setUpcomingBooks(
        books.sort((a, b) =>
          a.name.localeCompare(b.name, "sv", { sensitivity: "base" })
        )
      );
    });
  }, []);

  return (
    <div
      className={css`
        margin-top: 1rem;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        text-align: left;
      `}
    >
      {releasedBooks.length > 0 && (
        <>
          <div className={cssHeader}>Utgivna böcker</div>
          {releasedBooks.map((book, index) => (
            <Item
              type="book"
              imgSrc={`${book.key}.jpg`}
              name={book.name}
              about={book.info}
              bookInfo={{
                author: book.author,
                illustrator: book.illustrator,
                pages: book.pages,
                type: book.type,
                releaseDate: book.releaseDate,
                ISBN: book.ISBN,
              }}
              key={index}
            />
          ))}
        </>
      )}

      <br />
      <br />

      {upcomingBooks.length > 0 && (
        <>
          <div className={cssHeader}>Kommande böcker</div>
          {upcomingBooks.map((book, index) => (
            <Item
              type="book"
              imgSrc={`${book.key}.jpg`}
              name={book.name}
              about={book.info}
              bookInfo={{
                author: book.author,
                illustrator: book.illustrator,
                pages: book.pages,
                type: book.type,
                releaseDate: book.releaseDate,
                ISBN: book.ISBN,
              }}
              key={index}
            />
          ))}
        </>
      )}
    </div>
  );
};
