import React from "react";
import { CoverImage, InfoText } from "../components";

export const Start = () => {
  return (
    <>
      <CoverImage imgSrc="coverImg/start.jpg" title="OMNIPRINT" />
      <InfoText title="Välkommen">
        Välkommen till Omniprint Förlag, ett förlag som arbetar som ett
        traditionellt bokförlag, med samma kompetens och noggrannhet, men med
        den skillnaden att den bok du får i handen är helt och hållet din, då du
        står för produktionskostnaden för boken. Förlagets uppgift är att hjälpa
        dig med redaktionell och språklig bearbetning, med sättning och layout,
        korrekturläsning, tryckning och marknadsföring, med allt det som krävs
        för att din bok ska få de bästa förutsättningarna att synas på
        bokmarknaden.
        <br />
        <br />
        Lennart Lindskog
        <br />
        Förlagschef, Omniprint Förlag
      </InfoText>
    </>
  );
};
