import React from "react";
import { css } from "@emotion/css";
import { CoverImage, InfoText } from "../components";

export const About = () => {
  return (
    <>
      <CoverImage imgSrc="coverImg/about.jpg" title="OM FÖRLAGET" />
      <InfoText title="Om förlaget">
        Omniprint Förlag har sitt ursprung i det allmänutgivande bokförlaget
        Lindskog Förlag, ett förlag som började sin verksamhet 2004. Bokförlaget
        har sedan dess gett ut ett hundratal titlar, varav flera uppmärksammats
        med utmärkelser och priser.
        <br />
        <br />
        Produktionskapaciteten hos Lindskog Förlag är emellertid begränsad, och
        det är inte alltid möjligt att få ekonomi vid utgivningen av en bok. Av
        alla de manus som inkommer till förlaget är det ytterst få som förlaget
        väljer att ge ut, även om många manus håller mycket hög kvalitet. Därför
        bestämde förlaget hösten 2019 att starta systerförlaget Omniprint
        Förlag.
        <br />
        <br />
        Tanken med Omniprint Förlag är att ge författare möjligheten att ge ut
        sin bok med full äganderätt över verket och samtidigt ge den
        professionalitet ett traditionellt bokförlag erbjuder. Den som väljer
        att ge ut en bok på Omniprint Förlag kan alltså räkna med att få sin bok
        producerad med högsta kompetens och kunnighet. Allt görs med största
        noggrannhet i avsikt att producera en bok av högsta kvalitet, både
        beträffande text och fysisk utformning.
        <br />
        <br />
        <b>Kontakt</b>
        <br />
        Omniprint Förlag
        <br />
        Döbelnsgatan 44
        <br />
        113 52 Stockholm
        <br />
        <br />
        Tel: 070-352 50 30
        <br />
        Epost:{" "}
        <a
          className={css`
            color: black;
            text-decoration: none;
          `}
          href="mailto:info@omniprintforlag.se"
        >
          info@omniprintforlag.se
        </a>
      </InfoText>
    </>
  );
};
