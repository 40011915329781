import React from "react";
import { useNavigate } from "react-router-dom";
import { css } from "@emotion/css";
import { useWindowSize } from "../helpers";
import { Navbar } from ".";

export const Header = React.forwardRef((props, ref) => {
  const navigate = useNavigate();
  const windowWidth = useWindowSize();
  const navbarItemsLeft = [
    { id: "start", name: "OMNIPRINT" },
    { id: "hur", name: "HUR" },
    { id: "priser", name: "PRISER" },
  ];
  const navbarItemsRight = [
    { id: "bocker", name: "BÖCKER" },
    { id: "forfattare", name: "FÖRFATTARE" },
    { id: "om", name: "OM FÖRLAGET" },
  ];

  return (
    <div
      className={css`
        position: fixed;
        width: inherit;
        background: rgba(255, 255, 255, 0.9);
        z-index: 1;
      `}
      ref={ref}
    >
      {windowWidth < 1000 ? (
        <div
          className={css`
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            padding-bottom: 0.5rem;
          `}
        >
          <img
            className={css`
              height: 5rem;
              cursor: pointer;
              object-fit: contain;
            `}
            src="img/logo-text.png"
            onClick={() => navigate("/")}
            alt=""
          />
          <Navbar navbarItems={[...navbarItemsLeft, ...navbarItemsRight]} />
        </div>
      ) : (
        <div
          className={css`
            display: flex;
            justify-content: space-between;
            box-sizing: border-box;
            padding: 1rem 0;
          `}
        >
          <Navbar navbarItems={navbarItemsLeft} />
          <img
            className={css`
              height: 5rem;
              cursor: pointer;
              object-fit: contain;
            `}
            src="img/logo-text.png"
            onClick={() => navigate("/")}
            alt=""
          />
          <Navbar navbarItems={navbarItemsRight} />
        </div>
      )}
    </div>
  );
});
