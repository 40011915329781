import React from "react";
import ReactMarkdown from "react-markdown";
import { css } from "@emotion/css";
import { getDate } from "../helpers";

export const Item = ({ type, imgSrc, name, about, bookInfo }) => {
  return (
    <div
      className={css`
        margin-top: 2rem;
        display: flex;

        @media (max-width: 1000px) {
          flex-direction: column;
        }
      `}
    >
      <img
        className={css`
          width: 100%;
          max-width: 20rem;
          height: ${type === "author" ? "20rem" : ""};
          object-fit: ${type === "author" ? "cover" : "contain"};
          object-position: top;
          flex-shrink: 0;
        `}
        src={`img/${type}/${imgSrc}`}
        alt=""
      />
      <div
        className={css`
          margin: 0 2rem;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          line-height: 175%;

          @media (max-width: 1000px) {
            margin: 2rem 0;
          }
        `}
      >
        <div
          className={css`
            font-size: 1.25rem;
            color: var(--orange);
            font-weight: 700;
          `}
        >
          {name}
        </div>

        {bookInfo && (
          <div
            className={css`
              text-align: left;
              font-size: 0.85rem;
              color: var(--dark-grey);
            `}
          >
            {bookInfo.author && `Av ${bookInfo.author}`}
            {bookInfo.author && <br />}
            {bookInfo.illustrator && `Illustrerad av ${bookInfo.illustrator}`}
            {bookInfo.illustrator && <br />}
            {bookInfo.pages && `${bookInfo.pages} s. `}
            {bookInfo.type && bookInfo.type}
            {(bookInfo.pages || bookInfo.type) && <br />}
            {bookInfo.releaseDate &&
              `Utgivningsdatum ${getDate(bookInfo.releaseDate)}`}
            {bookInfo.releaseDate && <br />}
            {bookInfo.ISBN && `ISBN ${bookInfo.ISBN}`}
          </div>
        )}

        <div
          className={css`
            text-align: left;
            color: var(--dark-grey);
            font-size: 1rem;
          `}
        >
          <ReactMarkdown>{about}</ReactMarkdown>
        </div>
      </div>
    </div>
  );
};
