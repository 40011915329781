import React, { createRef, useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { css } from "@emotion/css";
import { Header, Footer } from "./components";
import { Start, How, Prices, Books, Authors, About } from "./pages";

export const AppRouter = () => {
  const headerRef = createRef();
  const [headerHeight, setHeaderHeight] = useState(0);

  useEffect(() => {
    if (headerRef.current) {
      setHeaderHeight(headerRef.current.offsetHeight);

      function handleResize() {
        setHeaderHeight(headerRef.current.offsetHeight);
      }
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }
  }, [headerRef]);

  return (
    <div
      className={css`
        text-align: center;
        font-family: Poppins;
        display: flex;
        justify-content: center;
      `}
    >
      <div
        className={css`
          width: 90%;
        `}
      >
        <Router>
          <Header ref={headerRef} />
          <div
            className={css`
              box-sizing: border-box;
              min-height: 100vh;
              padding-top: ${`${headerHeight}px`};
              display: flex;
              flex-direction: column;
            `}
          >
            <div
              className={css`
                flex-grow: 1;
              `}
            >
              <Routes>
                <Route exact path="/" element={<Start />} />
                <Route path="/hur" element={<How />} />
                <Route path="/priser" element={<Prices />} />
                <Route path="/bocker" element={<Books />} />
                <Route path="/forfattare" element={<Authors />} />
                <Route path="/om" element={<About />} />
              </Routes>
            </div>
            <Footer />
          </div>
        </Router>
      </div>
    </div>
  );
};
