import React from "react";
import { css } from "@emotion/css";

export const CoverImage = ({ imgSrc, title }) => {
  return (
    <div
      className={css`
        height: 50vh;
        position: relative;
        background-image: url(img/${imgSrc});
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      `}
    >
      {title && (
        <div
          className={css`
            background: rgba(0, 0, 0, 0.5);
            height: 100%;
            width: 100%;
            position: absolute;
            opacity: 0;
            transition: 0.5s;
            display: flex;
            justify-content: center;
            align-items: center;

            &:hover {
              opacity: 1;
            }
          `}
        >
          <div
            className={css`
              font-family: Playfair Display;
              transform: scale(1.1, 1);
              font-size: 3rem;
              color: white;
            `}
          >
            {title.toLowerCase()}
          </div>
        </div>
      )}
    </div>
  );
};
