import React from "react";
import { CoverImage, InfoText } from "../components";

export const Prices = () => {
  return (
    <>
      <CoverImage imgSrc="coverImg/prices.jpg" title="PRISER" />
      <InfoText title="Priser">
        Priset för att ge ut en bok på Omniprint Förlag beror på många faktorer:
        antal böcker som ska tryckas, bokens omfång, om boken ska tryckas i
        svartvitt eller i färg och vilken kvalitet du vill ha på pappret. En
        annan faktor som påverkar priset är det redaktionella arbetet. En text
        som är relativt färdigarbetad kräver mindre redaktionellt arbete än en
        text som inte är lika genomarbetad.
        <br />
        <br />
        Vill du ha ett prisförslag på vad det kostar att göra din bok, skriv då
        till förlaget och berätta hur du tänker dig din bok så återkommer vi med
        en offert. Du kan även ringa till förlaget och berätta hur du vill ha
        din bok.
      </InfoText>
    </>
  );
};
